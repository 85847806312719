import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { basename } from './config/Config';
const Login = lazy(() => import('./portal/pages/Login'));
const Portal = lazy(() => import('./portal/Home'));
const Content = lazy(() => import('./content/Home'));
const Subject = lazy(() => import('./content/Subject'));
const SDP = lazy(() => import('./sdp/Home'));
const Module = lazy(() => import('./sdp/Subject'));
const Learn = lazy(() => import('./dlms/Home'));
const Lesson = lazy(() => import('./dlms/Subject'));
const Question = lazy(() => import('./qb/Home'));
const Question_home = lazy(() => import('./qb/Subject'));
const Peps = lazy(() => import('./peps/Home'));
const QMS = lazy(() => import('./peps/Subject'));
const Soltent = lazy(() => import('./soltent/Home'));
const Solject = lazy(() => import('./soltent/Subject'));
const Payment = lazy(() => import('./portal/PayOnline'));

const Loader = () => (
  <div className="suspenseLoader">
    <img alt="Loading..." src="/loader.gif"/>
  </div>
)

const Routes = () => (
  <BrowserRouter basename={ basename }>
    <Suspense fallback={
      <Loader/>
    }>
      <Switch>
        <Route path="/" component={ Login } exact />
        <Route path="/portal" component={ Portal }/>
        <Route path="/payment" component={ Payment }/>
        <Route path="/content" render={({ match: { url } }) => 
          (
            <>
              <Route path={`${ url }/`} component={ Content } exact />
              <Route path={`${ url }/curr`} component={ Subject } />
              <Route path={`${ url }/subject`} component={ Subject } />
              <Route path={`${ url }/chapter`} component={ Subject } />
            </>
          )
        }
        />
        <Route path="/qb" render={({ match: { url } }) =>
          (
            <>
              <Route path={`${ url }/`} component={ Question } exact />
              <Route path={`${ url }/curr`} component={ Question_home } />
              <Route path={`${ url }/subject`} component={ Question_home } />
              <Route path={`${ url }/chapter`} component={ Question_home } />
            </>
          )
        }
        />
        <Route path="/sdp" render={({ match: { url } }) =>
          (
            <>
              <Route path={`${ url }/`} component={ SDP } exact />
              <Route path={`${ url }/curr`} component={ Module } />
              <Route path={`${ url }/subject`} component={ Module } />
              <Route path={`${ url }/chapter`} component={ Module } />
            </>
          )
        }
        />
        <Route path="/dlms" render={({ match: { url } }) => 
          (
            <>
              <Route path={`${ url }/`} component={ Learn } exact />
              <Route path={`${ url }/subject`} component={ Lesson } />
              <Route path={`${ url }/chapter`} component={ Lesson } />
            </>
          )
        }
        />
        <Route path="/peps" render={({ match: { url } }) => 
          (
            <>
              <Route path={`${ url }/`} component={ Peps } exact />
              <Route path={`${ url }/subject`} component={ QMS } />
              <Route path={`${ url }/chapter`} component={ QMS } />
            </>
          )
        }
        />
        <Route path="/contents" render={({ match: { url } }) => 
          (
            <>
              <Route path={`${ url }/`} component={ Soltent } exact />
              <Route path={`${ url }/subject`} component={ Solject } />
              <Route path={`${ url }/chapter`} component={ Solject } />
            </>
          )
        }
        />
        <Route path="**" render={() => (<Redirect to="/" />)}/>
      </Switch>
    </Suspense>
  </BrowserRouter>
)

export default Routes;